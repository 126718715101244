<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item md-medium-size-100 md-size-80">
        <send-email-form data-background-color="green"> </send-email-form>
      </div>
      <div
        class="md-layout-item md-size-100"
      >
        <md-card>
          <md-card-header data-background-color="orange">
            <h4 class="title">Jobs for sending email</h4>
          </md-card-header>
          <md-card-content>
            <md-table v-model="jobs" table-header-color="Orange">
              <md-table-row :class="item.done_dt ? '' : 'grey-text'" slot="md-table-row" slot-scope="{ item }">
                <md-table-cell md-label="ID">{{ item.id }}</md-table-cell>
                <md-table-cell md-label="Started">
                  {{ formatDate(item.started_dt, "YYYY-MM-DD HH:MM") }}
                </md-table-cell>
                <md-table-cell md-label="Receiver">{{ item.job_type ? 'All' : item.country }}</md-table-cell>
                <md-table-cell md-label="Success">{{ item.success }}</md-table-cell>
                <md-table-cell md-label="Error">{{ item.error }}</md-table-cell>
                <md-table-cell md-label="Total">{{ item.total }}</md-table-cell>
                <md-table-cell md-label="Last done">
                  {{ formatDate(item.done_dt, "YYYY-MM-DD HH:MM") }}
                </md-table-cell>
                <md-table-cell md-label="Header">{{ item.email_header }}</md-table-cell>
              </md-table-row>
            </md-table>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import config from '@/config';
import moment from "moment";
import { SendEmailForm } from "@/pages";

export default {
  components: {
    SendEmailForm,
  },
  data() {
    return {
      jobs: [],
    };
  },
  beforeCreate: function () {
    axios
      .get('/admin/email/job',
        { 'headers': { 'Authorization': 'Token ' + localStorage.token } })
      .then(response => {
        if (response.data.jobs) {
          let list = response.data.jobs;
          list.sort((a, b) => b.id - a.id);

          this.jobs = list;
        }
      })
      .catch(error => {
        if (error.response.status == 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('uid');
          this.$router.push('/login');
        } else if (error.response.status == 403) {
          this.$router.push('/403');
        }
      })
  },
  methods: {
    formatDate(dateString, format) {
      const date = moment(dateString);
      if (date.isValid()) {
        return date.format(format);
      }
      return "";
    },
  }
};
</script>
