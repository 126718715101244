<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50"
      >
        <md-card>
          <md-card-header data-background-color="orange">
            <div class="d-flex justify-space-between align-center">
              <h4 class="title">Clubs</h4>

              <md-field md-inline md-layout="box" style="margin: 0px; padding-top: 0px; min-height: 41px; width: 70%;">
                <md-input placeholder="Search by name..." v-model="clubName" @keyup.enter="handleEnterKey" />
              </md-field>
            </div>
          </md-card-header>
          <md-card-content>
            <md-list class="overflow-y-scroll" style="height: 85vh;">
              <md-list-item
                v-for="club in club_all"
                :key="club.team_id"
                >
                  <div class="flex-row align-center">
                    <img :src="club.team_image" class="league-logo" />

                    {{ club.team_name }}
                  </div>

                  <md-button class="md-icon-button" @click="add_club(club)">
                    <md-icon>add</md-icon>
                  </md-button>
              </md-list-item>
            </md-list>
          </md-card-content>
        </md-card>
      </div>
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50"
      >
        <md-card>
          <md-card-header data-background-color="green">
            <div class="d-flex justify-space-between align-center">
              <h4 class="title">Top Clubs</h4>
              
              <md-button class="md-raised" :disabled="!updated" @click="save_club()">
                Save
              </md-button>
            </div>
          </md-card-header>
          <md-card-content class="overflow-y-scroll" style="height: 90vh;">
            <md-table v-model="top_club" md-card>
              <md-table-row
                slot="md-table-row"
                slot-scope="{ item }"
                md-selectable="single"
              >
                <md-table-cell>
                  <img :src="item.team_image" class="league-logo" />
                </md-table-cell>

                <md-table-cell md-label="Club">
                  {{ item.team_name }}

                  <md-tooltip md-direction="top">{{ item.country_name }}</md-tooltip>
                </md-table-cell>

                <md-table-cell md-label="Action">
                  <md-button class="md-just-icon md-simple md-primary" @click="upward(item.team_id)">
                    <md-icon>arrow_upward</md-icon>
                    <md-tooltip md-direction="top">Up</md-tooltip>
                  </md-button>
                  <md-button class="md-just-icon md-simple md-primary" @click="downward(item.team_id)">
                    <md-icon>arrow_downward</md-icon>
                    <md-tooltip md-direction="top">Down</md-tooltip>
                  </md-button>
                  <md-button class="md-just-icon md-simple md-danger" @click="remove(item.team_id)">
                    <md-icon>close</md-icon>
                    <md-tooltip md-direction="top">Remove</md-tooltip>
                  </md-button>
                </md-table-cell>
              </md-table-row>
            </md-table>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  NavTabsCard,
  NavTabsTable,
} from "@/components";

import axios from 'axios';
import config from '@/config';
import { array, sortBy } from 'lodash';

axios.defaults.baseURL = config.API_URL;

export default {
  components: {
  },
  data() {
    return {
        clubName: "",
        club_all: [],
        top_club: [],
        updated: false,
    };
  },
  beforeCreate: function () {
    axios
      .get('/top/club')
      .then(response => {
        this.top_club = response.data.top_club;
      })
      .catch(error => {
      })
  },
  methods: {
    handleEnterKey() {
      axios
        .get('/team/name/' + this.clubName)
        .then(response => {
          this.club_all = response.data;
        })
        .catch(error => {
        })
    },
    
    add_club(cl) {
      let key = null;

      this.top_club.forEach((element, index) => {
        if (element.team_id == cl.team_id) {
          key = index;
          return;
        }
      });

      if (key == null) {
        this.top_club.push({
          'team_id': cl.team_id,
          'team_name': cl.team_name,
          'team_image': cl.team_image,
          'country_name': cl.country_name,
        });

        this.updated = true;
      }
    },

    upward (ind) {
      let arr = this.top_club.slice();
      let key = 0;

      arr.forEach((element, index) => {
        if (element.team_id == ind) {
          key = index;
          return;
        }
      });
      
      if (key > 0) {
        [arr[key - 1], arr[key]] = [arr[key], arr[key - 1]];

        this.updated = true;
      }

      this.top_club = arr;
    },

    downward (ind) {
      let arr = this.top_club.slice();
      let key = 0;

      arr.forEach((element, index) => {
        if (element.team_id == ind) {
          key = index;
          return;
        }
      });
      
      if (key < arr.length - 1) {
        [arr[key], arr[key + 1]] = [arr[key + 1], arr[key]];

        this.updated = true;
      }

      this.top_club = arr;
    },
    
    remove (ind) {
      let arr = this.top_club.slice();
      let key = 0;

      arr.forEach((element, index) => {
        if (element.team_id == ind) {
          key = index;
          return;
        }
      });

      arr.splice(key, 1);

      this.updated = true;
      
      this.top_club = arr;
    },

    save_club () {
      axios
        .post('/admin/top/club', { 'club': this.top_club },
          { 'headers': { 'Authorization': 'Token ' + localStorage.token } })
        .then(response => {
        })
        .catch(error => {
          if (error.response.status == 401) {
            localStorage.removeItem('token');
            localStorage.removeItem('uid');
            this.$router.push('/login');
          } else if (error.response.status == 403) {
            this.$router.push('/403');
          }
        })
      
      this.updated = false;
    }
  }
};
</script>

<style lang="scss" scoped>
  $list-width: 320px;

  .country-flag {
    width: 27px;
    height: 17px;
    box-shadow:2px 2px 2px grey;
    margin-right: 10px;
  }

  .league-logo {
    width: 30px;
    height: 30px;
  }

  .md-button.md-just-icon {
    height: 15px;
  }

  .md-list-item-text.flex-row {
    flex-direction: row;
  }
</style>

<!-- Import CSS file -->
<style src="@/assets/common.css"></style>