import DashboardLayout from "@/pages/Layout/DashboardLayout.vue";

import User from "@/pages/User.vue";
import UserProfile from "@/pages/UserProfile.vue";
import TableList from "@/pages/TableList.vue";
import Typography from "@/pages/Typography.vue";
import Icons from "@/pages/Icons.vue";
import Maps from "@/pages/Maps.vue";
import Notifications from "@/pages/Notifications.vue";
import UpgradeToPRO from "@/pages/UpgradeToPRO.vue";
import Forbidden from "@/pages/Forbidden.vue";
import Login from "@/pages/Login.vue";
import Data from "@/pages/Data.vue";
// import Dashboard from "@/pages/Dashboard.vue";
import Email from "@/pages/Email.vue";
import TopClub from "@/pages/TopClub.vue";

const routes = [
  {
    path: "/",
    component: DashboardLayout,
    // redirect: "/dashboard",
    children: [
      {
        path: "",
        name: "User",
        component: User,
      },
      {
        path: "data",
        name: "Data",
        component: Data,
      },
      {
        path: "email",
        name: "Email",
        component: Email,
      },
      // {
      //   path: "topclub",
      //   name: "TopClub",
      //   component: TopClub,
      // },
      // {
      //   path: "dashboard",
      //   name: "Dashboard",
      //   component: Dashboard,
      // },
      // {
      //   path: "user",
      //   name: "User Profile",
      //   component: UserProfile,
      // },
      // {
      //   path: "table",
      //   name: "Table List",
      //   component: TableList,
      // },
      // {
      //   path: "typography",
      //   name: "Typography",
      //   component: Typography,
      // },
      // {
      //   path: "icons",
      //   name: "Icons",
      //   component: Icons,
      // },
      // {
      //   path: "maps",
      //   name: "Maps",
      //   meta: {
      //     hideFooter: true,
      //   },
      //   component: Maps,
      // },
      // {
      //   path: "notifications",
      //   name: "Notifications",
      //   component: Notifications,
      // },
      // {
      //   path: "upgrade",
      //   name: "Upgrade to PRO",
      //   component: UpgradeToPRO,
      // }
    ],
    meta: { requiresAuth: true }
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/403",
    component: Forbidden
  }
];

export default routes;
