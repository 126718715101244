<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-33 md-xsmall-size-100 md-size-25"
      >
        <stats-card data-background-color="green">
          <template slot="header">
            <md-icon>verified_user</md-icon>
          </template>

          <template slot="content">
            <p class="category">
              <b>Registered Users</b>
            </p>
            <h3 class="title">
              <b>{{ total_acc }}</b>
            </h3>
          </template>

          <!-- <template slot="footer">
            <div class="stats">
              <h6><b>Registered</b></h6>
            </div>
          </template> -->
        </stats-card>
      </div>
      <div
        class="md-layout-item md-medium-size-33 md-xsmall-size-100 md-size-25"
      >
        <stats-card data-background-color="orange">
          <template slot="header">
            <md-icon>payments</md-icon>
          </template>

          <template slot="content">
            <p class="category">
              <b>Subscribed Users</b>
            </p>
            <h3 class="title">
              <b>{{ subscribedAcc }}</b>
            </h3>
          </template>
        </stats-card>
      </div>
      <div
        class="md-layout-item md-medium-size-33 md-xsmall-size-100 md-size-50"
      >
        <stats-card data-background-color="blue">
          <template slot="header">
            <md-icon class="hidden-sm">public</md-icon>
          </template>

          <template slot="content">
            <p class="category">
              <b>Country</b>
            </p>
            
            <div class="d-flex justify-space-between">
              <country-select class="md-layout-item md-size-70" v-model="country" :whiteList="whitelist_country" />
              
              <h3 class="title">
                <b>{{ country ? count_country[country] : 0 }}</b>
              </h3>
            </div>
          </template>
        </stats-card>
      </div>
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <md-card>
          <md-card-header data-background-color="">
            <h4 class="title">Users</h4>
            <p class="category">All active users</p>
          </md-card-header>
          <md-card-content>
            <md-table v-model="people" table-header-color="blue" md-card>
              <md-table-row :class="item[4] ? '' : 'grey-text'" slot="md-table-row" slot-scope="{ item }">
                <md-table-cell md-label="Username" md-sort-by="name">
                  {{ item[1] }}
                </md-table-cell>
                <md-table-cell md-label="Email" md-sort-by="email">
                  {{ item[2] }}
                </md-table-cell>
                <md-table-cell md-label="Joined Date" md-sort-by="title">
                  {{ formatDate(item[3], "YYYY-MM-DD") }}
                </md-table-cell>
                <md-table-cell md-label="Action">
                  <md-button
                    class="md-simple"
                    :class="item[4] ? 'md-primary' : 'md-danger'"
                    @click="update_status(item)">
                    {{ item[4] ? "Active" : "Suspended" }}
                  </md-button>
                </md-table-cell>
              </md-table-row>
            </md-table>

            <md-dialog-confirm
              :md-active.sync="update_active"
              :md-title="`Are you going to ${selected[4] ? 'suspend' : 'activate'} this account?`"
              md-confirm-text="Yes"
              md-cancel-text="Cancel"
              @md-cancel="onCancel"
              @md-confirm="onConfirm" />
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import { StatsCard } from "@/components";
import axios from "axios";
import config from "@/config";
import moment from "moment";

axios.defaults.baseURL = config.API_URL;

export default {
  components: {
    StatsCard,
  },
  data() {
    return {
      total_acc: 0,
      subscribedAcc: 0,
      selected: [],
      people: [],
      update_active: false,
      country: null,
      count_country: {},
      whitelist_country: [],
    };
  },
  beforeCreate: function () {
    axios
      .get("/admin/user/count", {
        headers: { Authorization: "Token " + localStorage.token },
        })
      .then(response => {
        this.total_acc = response.data.total;
        this.subscribedAcc = response.data.subscribed;

        let list = [], json_count = {};
        response.data.ct_list.forEach(el => {
          list.push(el.country);
          json_count[el.country] = el.total;
        });
        
        this.whitelist_country = list;
        this.count_country = json_count;

        this.getUsers();
      })
      .catch(error => {
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("uid");
          this.$router.push("/login");
        } else if (error.response.status == 403) {
          this.$router.push("/403");
        }
      })
  },
  methods: {
    update_status (item) {
      this.selected = item;
      this.update_active = true;
    },

    onCancel () {
      this.update_active = false;
    },

    onConfirm () {
      axios
      .get("/admin/user/status/" + this.selected[0], {
        headers: { Authorization: "Token " + localStorage.token },
      })
      .then((response) => {
        let arr = this.people.slice();

        const index = arr.findIndex((item) => item[0] === this.selected[0]);

        if (index !== -1) {
          arr[index][4] = !arr[index][4];

          this.people = arr;
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("uid");
          this.$router.push("/login");
        } else if (error.response.status == 403) {
          this.$router.push("/403");
        }
      });
    },
    
    getUsers () {
      axios
      .get("/admin/user/info", {
        headers: { Authorization: "Token " + localStorage.token },
        })
      .then((response) => {
        this.people = response.data.users;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("uid");
          this.$router.push("/login");
        } else if (error.response.status == 403) {
          this.$router.push("/403");
        }
      })
    },

    formatDate(dateString, format) {
      const date = moment(dateString);
      if (date.isValid()) {
        return date.format(format);
      }
      return "";
    },
  },
};
</script>

<style scoped>
.md-table-cell {
  padding: 0;
}

.md-table-cell .md-button {
  height: unset;
}

.md-table-cell .md-button .md-ripple {
  padding: unset;
}
</style>