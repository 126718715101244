<template>
  <ul class="nav nav-mobile-menu">
    <li>
      <drop-down>
        <a slot="title" class="dropdown-toggle" data-toggle="dropdown">
          <i class="material-icons">person</i>
          <p>Administrator</p>
        </a>
        <ul class="dropdown-menu dropdown-menu-right">
          <li><a @click="logout()">Sign Out</a></li>
        </ul>
      </drop-down>
    </li>
  </ul>
</template>
<script>
export default {
  data() {
    return {
    };
  },
  methods: {
    logout() {
      localStorage.removeItem('token');
      localStorage.removeItem('uid');
      this.$router.push('/login');
    },
  },
};
</script>
