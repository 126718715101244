<template>
  <form>
    <md-card>
      <md-card-header :data-background-color="dataBackgroundColor">
        <h4 class="title">Send Email to Customers</h4>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-small-size-100 md-size-33">
            <md-field>
              <label>From: support@Foot-corner.com</label>
            </md-field>
          </div>
          <div class="d-flex align-center md-layout-item md-small-size-100 md-size-40">
            <label>To: </label>

            <md-field>
              <country-select v-model="country" :disabled="all_customer" />
            </md-field>
          </div>
          <div class="d-flex align-center justify-space-between md-layout-item md-small-size-50 md-size-15">
            <input type="checkbox" v-model="all_customer" />

            <span>All customers</span>
          </div>
          <div class="md-layout-item md-size-100">
            <md-field>
              <label>Subject</label>
              <md-input v-model="subject" type="text"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-size-100 md-height-50">
            <md-field>
              <label>Message</label>
              <md-textarea v-model="message"></md-textarea>
            </md-field>
          </div>
          <div class="md-layout-item md-size-100 text-right">
            <md-button class="md-raised md-success" @click="send_email()">Send</md-button>
          </div>

          <md-dialog-confirm
            :md-active.sync="send_active"
            md-title="Are you going to send an email?"
            md-confirm-text="Yes"
            md-cancel-text="Cancel"
            @md-cancel="onCancel"
            @md-confirm="onConfirm" />
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>
<script>
import axios from 'axios'
import config from '@/config'

export default {
  name: "send-email-form",
  props: {
    dataBackgroundColor: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      country: "",
      message: "",
      send_active: false,
      subject: "",
      all_customer: false,
    };
  },
  methods: {
    send_email() {
      if (this.subject != "" && this.message != "" && ( this.country != "" || this.all_customer)) {
        this.send_active = true
      }
    },

    onCancel () {
      this.send_active = false
    },

    onConfirm () {
      axios
      .post('/admin/email/send',
        { subject: this.subject, message: this.message, receiver: this.country, flag: this.all_customer },   // flag: false - country code, true - all customers
        { headers: { Authorization: "Token " + localStorage.token } })
      .then((response) => {
        switch (response.data.type) {
          case 0:
            // exceed limit on daily sending email
            this.notifyVue("top", "center", "Failed due to the limit on daily sending email.", "warning")
            break
          case 1:
            this.notifyVue("top", "center", "No receivers.", "warning")
            break
          case 2:
            let msg = "Sent email to " + response.data.count + " users"

            this.notifyVue("top", "center", msg, "success")
            break
          default:
            break
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("uid");
          this.$router.push("/login");
        } else if (error.response.status == 403) {
          this.$router.push("/403");
        } else if (error.response.status == 500) {
          this.notifyVue("bottom", "center", "Failed to send an email.", "danger")
        }
      });
    },

    notifyVue(verticalAlign, horizontalAlign, msg, type) {
      this.$notify({
        message: msg,
        icon: "add_alert",
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: type,
        timeout: 5000
      });
    },
  }
};
</script>
<style>
.md-field.md-has-textarea:not(.md-autogrow) .md-textarea {
    min-height: 20vh !important;
}
</style>
