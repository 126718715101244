<template>
	<md-card class="md-card-profile">
		<md-card-content>
			<md-icon>lock</md-icon>
			<h4 class="card-title">403</h4>
			<p class="card-description">
					Access to this page is restricted
			</p>
		</md-card-content>
	</md-card>
</template>

<script>
export default {};
</script>
