<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-40"
      >
        <stats-card data-background-color="green">
          <template slot="header">
            <h2>{{ numberWithCommas(count.match) }}</h2>
          </template>

          <template slot="content">
            <p class="category">Total</p>
            <h3 class="title">Matches</h3>
          </template>

          <!-- <template slot="footer">
            <div class="stats">
              <md-icon>date_range</md-icon>
              Last 24 Hours
            </div>
          </template> -->
        </stats-card>
      </div>
      <div
        class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-30"
      >
        <stats-card data-background-color="orange">
          <template slot="header">
            <h2>{{ numberWithCommas(count.league) }}</h2>
          </template>

          <template slot="content">
            <p class="category">Total</p>
            <h3 class="title">Leagues</h3>
          </template>
        </stats-card>
      </div>
      <div
        class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-30"
      >
        <stats-card data-background-color="red">
          <template slot="header">
            <h2>{{ numberWithCommas(count.country) }}</h2>
          </template>

          <template slot="content">
            <p class="category">Total</p>
            <h3 class="title">Countries</h3>
          </template>
        </stats-card>
      </div>
      <div
        class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-30"
      >
        <stats-card data-background-color="blue">
          <template slot="header">
            <h2>{{ numberWithCommas(count.team) }}</h2>
          </template>

          <template slot="content">
            <p class="category">Total</p>
            <h3 class="title">Teams</h3>
          </template>
        </stats-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  StatsCard,
} from "@/components";
import axios from 'axios';
import config from '@/config';

axios.defaults.baseURL = config.API_URL;

export default {
  components: {
    StatsCard,
  },
  data() {
    return {
      count: {
        match: 0,
        league: 0,
        country: 0,
        team: 0
      }
    };
  },
  beforeCreate: function () {
    axios
      .get('/admin/db/count',
        { 'headers': { 'Authorization': 'Token ' + localStorage.token } })
      .then(response => {
        this.count = response.data.count;
      })
      .catch(error => {
        if (error.response.status == 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('uid');
          this.$router.push('/login');
        } else if (error.response.status == 403) {
          this.$router.push('/403');
        }
      })
  },
  methods: {
    numberWithCommas(number) {
      return number.toLocaleString();
    },
  }
};
</script>
