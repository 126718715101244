<template>
	<div class="md-layout md-alignment-center">
		<div class="md-layout-item md-small-size-100 md-size-33">
			<md-card class="md-card-profile md-size-100">
				<md-card-content>
					<div class="title">
						<h2>Log in</h2>
						<h4>Foot-Corner.com account</h4>
					</div>

					<div class="form">
						<md-field>
							<label>Username</label>
							<md-input	
								v-model="username"
								v-on:keyup="clearError"
								@keyup.enter.native="login"
								autofocus></md-input>
						</md-field>

						<md-field md-has-password>
							<label>Password</label>
							<md-input 
								v-model="password"
								v-on:keyup="clearError"
								@keyup.enter.native="login"
								type="password"></md-input>
						</md-field>
					</div>

					<p v-show="message" style="color: red">{{ message }}</p>

					<div class="actions md-layout md-alignment-center">
						<md-button class="md-raised md-primary" @click="login">Log in</md-button>
					</div>
				</md-card-content>
			</md-card>
		</div>
	</div>
</template>

<script>
import axios from 'axios'
import config from '@/config'

axios.defaults.baseURL = config.API_URL;
export default {
	data() {
		return {
			username: '',
			password: '',
      message: null,
		}
	},
	beforeCreate: function () {
    if (localStorage.token) {
      this.$router.replace('/')
    }
  },
  methods: {
    login () {
      if (this.username != '' && this.password != ''){
        axios
          .post('/admin/auth/login', {'username': this.username, 'password': this.password})
          .then((response) => {
            if (response.data.invalid == 1) {
              this.message = 'Invalid credential.';
            } else {
              localStorage.token = response.data.token;
              localStorage.uid = response.data.user;
              
              this.$router.replace('/');
            }
          });
      }
    },

    clearError : function () {
      this.message = null;
    }	
  }
}
</script>

<style>
/* Add necessary styles here */
</style>
